import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { colors } from "../constants/Colors"
import { device } from "../constants/breakpoints"

const ConfirmSection = styled.div`
  text-align: center;

  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 500px;
  padding-bottom: 200px;
  h4 {
    color: ${colors.BLACK_BLUE};
  }
  @media ${device.tablet} {
    width: 100%;
  }
`

const Sconfirmation = ({ location }) => {
  const { count } = location.state

  return (
    <Layout>
      <ConfirmSection>
        <h1>Grazie!</h1>
        <p>
          Abbiamo inoltrato la tua richiesta anche ad
          {count === 1 ? "un altro chirurgo" : "altri " + count + " chirurghi"},
          sarai contattato a breve!
        </p>
      </ConfirmSection>
    </Layout>
  )
}

export default Sconfirmation
